.uploaded_file {
  display: inline-block;
  border: 1px solid #000;
  border-style: dashed;
  padding: 5px;
  margin: 5px;
}
.custom_icon {
  margin-left: 10px;
  cursor: pointer;
}
.red {
  color: red;
}
nav.leftside {
  background-color: #0d1444;
  padding: 10px;
  position: fixed;
  height: 100%;
  width: 300px;
  color: #fff;
  z-index: 1200;
  top: 0;
  left: 0;
}
.nav-main-section {
  padding: 20px;
}

nav.leftside a {
  padding: 10px 15px;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  display: block;
}
nav.leftside a.active {
  background-color: #293697;
}

nav.leftside a:hover {
  background-color: #293697;
}
nav.leftside a span {
  vertical-align: text-bottom;
  margin-left: 5px;
}
.second-pane {
  margin-left: 300px;
  height: calc(100% - 100px);
}
.top-bar-custom {
  margin-left: 300px;
  width: auto !important;
}
.top-bar-page-heading {
  color: #000;
}
.top-bar-page-heading > span {
  font-weight: bold;
}
.margin-overview-row {
  margin: 10px 0 !important;
}
.bc-left-margin {
  margin: 10px 30px !important;
}
.text-end {
  text-align: right;
}
.custom-padding-10 {
  padding-top: 10px;
}
.download-icon-custom {
  vertical-align: middle;
}
.overview-details-1 {
  padding: 15px 30px;
  position: relative;
  max-width: 570px;
}
.overview-details-1 > svg {
  position: absolute;
  height: 200px;
  width: 100%;
  top: 0;
  left: 0;
}
.tile1-content-container {
  position: relative;
  font-weight: bold;
  z-index: 2;
}
.tile1-extra-details {
  margin: 10px 0;
}

.overview-details-2 {
  margin: 5px 0 5px 15px !important;
}

.report-card-inner {
  margin: 10px 0;
  height: 105px;
  display: flex;
  font-weight: bold;
}

.report-card-inner-icon-side {
  width: 70px;
  background-color: #0d1444;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  padding-top: 35px;
  vertical-align: middle;
}

.report-card-inner-text-side {
  flex-grow: 1;
  margin: 10px;
  position: relative;
}
.report-card-inner-text-side h1 {
  font-size: 40px;
  font-weight: 900;
}

.db-tile-icon-bg {
  height: 100px;
  border-radius: 5px;
  text-align: center;
  padding-top: 30px !important;
  text-align: center;
  color: #fff;
  margin: 20px !important;
  padding-left: 15px;
}
.worst-item-inner {
  padding: 10px;
}
.worst-title {
  margin: 10px 0;
  font-weight: bold;
}
.worst-item-inner-text-side {
  background-color: #f5f5f5;
  margin-bottom: 10px;
  border-left: 6px solid #0d1444;
  padding: 10px;
}
.donut-container {
  position: relative;
  height: 100%;
}
.donut-center-text {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 45%;
  font-size: 14px;
}
.donut-center-text > h2 {
  font-weight: 900;
}
.filter-by-jc-report-page {
  text-align: right;
  position: relative;
  height: 100%;
}
.report-jc-view-all_serialised-items {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-weight: bold;
  cursor: pointer;
}
.report-overview-bar-row {
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #e4e6f5;
  padding: 15px 20px;
}
.report-overview-chart-inner {
  margin-right: 10px;
}
.job-card-comparison-chart-parent svg .MuiBarElement-root:nth-child(1) {
  fill: #03082e;
}
.report-right-side-serialised-items-bar {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  z-index: 10000;
}
.report-right-side-serialised-items-bar > div {
  position: fixed;
  right: 0;
  top: 0;
  background-color: #fff;
  height: 100%;
  width: 600px;
}
.report-right-side-serialised-items-bar > div > .head {
  background-color: #0e1543;
  color: #fff;
  padding: 15px;
}
.right-sidebar-inner {
  padding: 20px 30px;
  overflow-y: auto;
  height: calc(100% - 54px);
}
.padding-0 {
  padding: 0 !important;
}
.stat-item-ov {
  height: 141px;
}
.ps-20 {
  padding-left: 20px;
}
.height-100 {
  height: calc(100% - 10px);
}
.vertical-bar {
  height: 100%;
  width: 120px;
}
.vb-in-tolerance {
  background-color: #66bb71;
}
.vb-in-repair {
  background-color: #fab838;
}
.vb-beyond-repair {
  background-color: #d82c2c;
}
.vb-legend {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
.vb-legend > div > div {
  margin: 10px 0;
}
.vb-legend > div > div > span {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  border-radius: 3px;
}
.circle-graphs {
  height: 400px;
  position: relative;
}
.circle-graphs > div {
  position: absolute;
  text-align: center;
  border-radius: 50%;
}
.circle-graphs .big {
  background-color: #d82c2c;
  width: 250px;
  height: 250px;
  left: 120px;
  top: 90px;
  padding-top: 100px;
  color: white;
}
.circle-graphs .mid {
  background-color: #ff9b9b;
  width: 200px;
  height: 200px;
  left: 370px;
  top: 30px;
  padding-top: 80px;
  color: black;
}
.circle-graphs .small {
  background-color: #f8d4d4;
  width: 150px;
  height: 150px;
  left: 355px;
  top: 230px;
  padding-top: 50px;
  color: black;
}

.circle-graphs .big2 {
  background-color: #ffbf00;
  width: 250px;
  height: 250px;
  left: 120px;
  top: 90px;
  padding-top: 100px;
  color: black;
}
.circle-graphs .mid2 {
  background-color: #feedb9;
  width: 200px;
  height: 200px;
  left: 460px;
  top: 30px;
  padding-top: 80px;
  color: black;
}
.circle-graphs .small2 {
  background-color: #eeebe4;
  width: 160px;
  height: 160px;
  left: 365px;
  top: 200px;
  padding-top: 50px;
  color: black;
  font-size: 14px;
}
.part-img {
  padding: 20px;
  height: 410px;
  text-align: center;
}
.part-img > img {
  height: 100%;
  max-width: 100%;
}
.part-img-3d {
  width: 100%;
  height: 100%;
}
.rotating:hover {
  -webkit-animation: rotating 10s linear infinite;
  animation: rotating 10s linear infinite;
}
@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.tile-1-heading {
  font-size: 26px;
}
.float-right {
  float: right;
}
.view-all-btn {
  background-color: #e4e6f5;
  color: #06092b;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: 700;
}
.view-all-btn:hover {
  background-color: #c0c4e2;
}
.view-all-btn:active {
  background-color: #9ea4d3;
}

.view-all-btn2 {
  font-size: 12px;
  vertical-align: middle;
}
.view-next-btn {
  position: absolute;
  top: 20px;
  right: 10px;
  width: 100px;
}
.serial-key-summary-heading {
  background-color: #060b44;
  color: #fff;
  padding: 15px 25px;
}
.img-responsive {
  width: 100%;
}
.relative {
  position: relative;
}
.bordered {
  border: 1px solid #555;
}
.margin-n {
  margin: 10px;
}
.hover-img:hover .img-hover-cover {
  opacity: 1;
  background-size: 280%;
}
.img-hover-cover {
  opacity: 0;
  transition: opacity 0.8s, background-size 0.4s;
  position: absolute;
  width: 250px;
  height: 250px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 8px solid #fff;
  border-radius: 50%;
  background-position: 50%;
  background-size: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  background-repeat: no-repeat;
}

.center {
  text-align: center;
}
.link {
  cursor: pointer;
}
.legend-section {
  display: inline-block;
}
.legend-section > span {
  display: inline-block;
  vertical-align: sub;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.yieldGraph {
  margin: 10px 0;
  height: 360px;
}
.legend-graph-section {
  display: inline-block;
  height: 300px;
  position: relative;
  cursor: pointer;
}
.legend-graph-section > p {
  color: #fff;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
}
.legend-graph-section:hover {
  height: 320px;
  vertical-align: super;
  margin: -20px 0;
}
.overall-yield {
  background-color: #d4d5e7;
  text-align: center;
  padding: 15px;
  margin: 0 -15px;
  margin-bottom: -22px;
  font-weight: bold;
  height: 175px;
  padding-top: 45px;
}
.overall-yield > div:nth-child(1) {
  font-size: 70px;
  margin-bottom: 0px;
}
.overall-yield > div {
  margin-top: -20px;
}
.overall-yield-part-tile {
  background-color: #aaa;
  padding: 20px;
  margin: 5px 15px;
  height: 190px;
  background-color: #d4d5e7;
}
.see-details-btn {
  background-color: #050c44;
  color: #fff;
}
.see-details-btn:hover {
  background-color: #132186;
}
.pb-2 {
  padding-bottom: 20px;
}
.part3-yield-pecent {
  font-size: 60px;
  font-weight: bold;
}
.overall-yield-subtitle {
  font-weight: bold;
  font-size: 20px;
  margin-left: 5px;
  margin-top: -20px;
  text-align: center;
}
.repair-legend {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #c6c8e8;
  vertical-align: sub;
}
.umr-legend {
  vertical-align: sub;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #0d1444;
}
.qo-legends {
  display: inline-block;
  margin-left: 20px;
  font-size: 14px;
}
.location-part-cover {
  margin: 30px;
  height: 315px;
  display: flex;
}
.location-part-cover div {
  color: #fff;
}
.location-part-cover > .big {
  width: 40%;
  height: 100%;
  background-color: #232648;
}
.location-part-cover .small-main {
  width: 60%;
  height: 100%;
}
.location-part-cover .small-main .small-main-inner {
  display: flex;
  height: 50%;
}
.location-part-cover .small-main .small-main-inner {
  white-space: nowrap;
}
.location-part-cover .small-main .small-1 {
  width: 60%;
  height: 100%;
  background-color: #454767;
}
.location-part-cover .small-main .small-2 {
  width: 40%;
  height: 100%;
  background-color: #c6c8e8;
}
.location-part-cover .small-main-inner .medium-1 {
  width: 70%;
  height: 100%;
  background-color: #969ad3;
}
.location-part-cover .small-main-inner .medium-2 {
  width: 30%;
  height: 100%;
  background-color: #9494a1;
}
.location-part-cover .tile-c {
  padding: 20px;
  text-align: center;
  padding-top: 15%;
  white-space: wrap;
}
.location-part-cover .tile-c strong {
  display: block;
}
.cg2 {
  font-size: 13px;
}
.cg2 .circle-graphs {
  height: 300px;
  position: relative;
}
.cg2 .circle-graphs > div {
  position: absolute;
  text-align: center;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  transition: transform 0.2s linear;
}
.cg2 .circle-graphs > div:hover {
  transform: scale(1.1);
}
.cg2 .circle-graphs .s1 {
  background-color: #0d1444;
  width: 170px;
  height: 170px;
  left: 180px;
  top: 10px;
  padding-top: 60px;
  color: white;
}
.cg2 .circle-graphs .s2 {
  background-color: #232966;
  width: 140px;
  height: 140px;
  left: 70px;
  top: 130px;
  padding-top: 40px;
  color: white;
}
.cg2 .circle-graphs .s3 {
  background-color: #232966;
  width: 140px;
  height: 140px;
  left: 320px;
  top: 130px;
  padding-top: 40px;
  color: white;
}
.cg2 .circle-graphs .s4 {
  background-color: #7a7ca9;
  width: 140px;
  height: 140px;
  left: 420px;
  top: 20px;
  padding-top: 50px;
  color: white;
}
.cg2 .circle-graphs .s5 {
  background-color: #a7a7ac;
  width: 100px;
  height: 100px;
  left: 500px;
  top: 150px;
  padding-top: 20px;
  color: black;
}

.cg2.cgh {
  height: 335px;
}

.cg2 .circle-graphs .s6 {
  background-color: #a32307;
  width: 190px;
  height: 190px;
  left: 100px;
  top: 100px;
  padding-top: 60px;
  color: white;
}
.cg2 .circle-graphs .s7 {
  background-color: #dd9586;
  width: 140px;
  height: 140px;
  left: 290px;
  top: 160px;
  padding-top: 40px;
  color: white;
}
.ok-nok-graph-container {
  padding: 5px 10px;
  position: relative;
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-weight: bold;
}

.ok-nok-graph-container > div {
  width: 450px;
  margin: auto;
  position: relative;
}

.ok-nok-graph-container .ok {
  position: absolute;
  top: 25px;
  left: 40px;
}
.ok-nok-graph-container .ok-1 {
  position: absolute;
  top: 40px;
  left: 80px;
  width: 40px;
  height: 10px;
  background-color: #66bb71;
}
.ok-nok-graph-container .ok-2 {
  position: absolute;
  top: 15px;
  left: 140px;
  width: 120px;
  height: 70px;
  background-color: #66bb71;
  color: #fff;
  font-size: 50px;
  text-align: center;
}

.ok-nok-graph-container .nok {
  position: absolute;
  top: 110px;
  left: 360px;
}
.ok-nok-graph-container .nok-1 {
  position: absolute;
  top: 115px;
  left: 300px;
  width: 40px;
  height: 10px;
  background-color: #d82c2c;
}
.ok-nok-graph-container .nok-2 {
  position: absolute;
  top: 85px;
  left: 160px;
  width: 120px;
  height: 70px;
  background-color: #d82c2c;
  color: #fff;
  font-size: 50px;
  text-align: center;
}
.ok-nok-graph-container .trend {
  position: absolute;
  top: 35px;
  left: 290px;
  color: #66bb71;
}
.ok-nok-graph-container .trend.down {
  color: #d82c2c;
}
.comparison-table table {
  width: 100%;
  border: none;
  font-weight: bold;
}
.comparison-table td {
  padding: 10px;
}
.comparison-table .dark-bg td {
  background-color: #40466d;
  color: #fff;
}
.comparison-table .tborder {
  border: 1px solid #eee;
}
.comparison-table .tborder2 {
  border: 1px solid #ccc;
}
.comparison-table td {
  text-align: center;
}
.comparison-table .data td:nth-child(1) {
  background-color: #e1e3f4;
}
.comparison-table .data td:nth-child(2) {
  background-color: #e5f8e0;
}
.comparison-table .data td:nth-child(3) {
  background-color: #f8e0e0;
}
.comparison-table .data td:nth-child(5) {
  background-color: #e1e3f4;
}
.comparison-table .data td:nth-child(6) {
  background-color: #e5f8e0;
}
.comparison-table .data td:nth-child(7) {
  background-color: #f8e0e0;
}

.comparison-table .data2 td:nth-child(1) {
  background-color: #e1e3f4;
}
.comparison-table .data2 td:nth-child(2) {
  background-color: #e5f8e0;
}
.comparison-table .data2 td:nth-child(3) {
  background-color: #f8e0e0;
}

.comparison-table .data2 td:nth-child(7) {
  background-color: #e5f8e0;
}
.comparison-table .data2 td:nth-child(8) {
  background-color: #f8e0e0;
}

.comparison-table .data3 td:nth-child(1) {
  background-color: #e1e3f4;
}

.comparison-table .compare-col {
  background-color: #0d1444 !important;
  color: #fff;
}

.comparison-table .compare-col2 {
  background-color: #cbcfed !important;
  color: #03082e !important;
}

.expand-btn {
  text-align: right;
}

.report-right-side-serialised-items-bar > div.full {
  width: calc(100% - 300px);
}
.close-btn {
  position: absolute;
  right: 40px;
  top: 12px;
  font-size: 20px;
  cursor: pointer;
}
.compare-header {
  font-size: 20px;
  float: right;
}
.compare-header > div {
  display: inline-block;
  margin: 5px 20px;
}
.compare-header > div > span:nth-child(2) {
  margin-left: 10px;
  margin-right: 10px;
}
.converter-scale {
  float: right;
}
.comparison-batch-tile {
  background-color: #efefef;
  margin-top: 10px;
  height: 180px;
  border: 1px solid #ccc;
}
.comparison-batch-tile .comparison-inner {
  width: 80%;
  margin: auto;
  padding: 20px 0;
}
.left {
  float: left;
}
.no-padding-tabs > div > div {
  padding: 0;
}
.custom-tab-style button.Mui-selected {
  color: #fff !important;
  background-color: #0d1444;
}
.ok-nok-label {
  padding: 5px;
  padding-left: 10px;
  position: absolute;
  z-index: 1;
  top: -35px;
  left: 25px;
}
.route-legend {
  float: right;
  padding-right: 20px;
}
.route-legend span {
  display: inline-block;
  margin-left: 25px;
  margin-right: 2px;
}
.route-legend .not-captured {
  width: 45px;
  height: 15px;
  border-bottom: 2px dotted;
}
.route-legend .umr {
  width: 25px;
  height: 15px;
  background-color: #c32907;
}
.route-legend .failed {
  width: 25px;
  height: 15px;
  background-color: #fbb5a6;
}
.legend-details-panel {
  margin-bottom: 20px;
}
.legend-details-panel div:nth-child(1) {
  display: inline-block;
  padding: 0 4px;
  background-color: #0e1543;
  color: #fff;
  width: 160px;
  text-align: center;
  border-radius: 3px 3px 0 0;
  font-weight: bold;
}
.legend-details-panel div:nth-child(2) {
  border: 1px solid #000;
  border-radius: 0 0 3px 3px;
  padding: 15px;
  font-weight: bold;
}
.app-timeline-notification {
  cursor: pointer;
}
.custom-funnel svg path:nth-of-type(1) {
  fill: #202b59;
}
.custom-funnel svg path:nth-of-type(2) {
  fill: #485283;
}
.custom-funnel svg path:nth-of-type(3) {
  fill: #6d76b2;
}
.custom-funnel svg path:nth-of-type(4) {
  fill: #8b8fc2;
}
.custom-funnel svg path:nth-of-type(5) {
  fill: #c2d1e2;
}

.custom-funnel svg g text {
  fill: #fff !important;
}
.text-right {
  text-align: right;
  padding-right: 10px;
}
.pie-chart {
  max-width: 600px;
  position: relative;
}
.pie-chart .center-label {
  position: absolute;
  text-align: center;
  left: 31%;
  top: 100px;
  font-size: 15px;
}
.pie-chart .cl-adjustment {
  left: 34%;
}
.pie-chart .center-label strong {
  font-size: 20px;
}
.color-map-main-img.upsidedown {
  transform: rotate(180deg);
}
.data-na {
  height: 250px;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  padding-top: 20px;
}
.color-map-main-img {
  height: 360px;
}
.defect-img-container {
  position: relative;
  height: 400px;
}
.defect-img-container .defect-location-container {
  position: absolute;
  z-index: 1;
}
.defect-img-container .defect-location-container.lug {
  left: calc(50% + 270px);
  bottom: 360px;
}
.defect-img-container .defect-location-container.fof {
  left: calc(50% + 280px);
  top: 50px;
}
.defect-img-container .defect-location-container.rif {
  left: calc(50% + 210px);
  bottom: 50px;
}
.defect-img-container .defect-location-container.fif {
  left: calc(50% + 200px);
  top: 330px;
}
.defect-img-container .defect-location-container.slot {
  left: calc(50% - 450px);
  top: 280px;
}
.defect-img-container .defect-location-container.lea {
  left: calc(50% + 290px);
  top: 160px;
}
.defect-img-container .defect-location {
  padding: 5px;
  background-color: #e4e6f5;
  border: 1px solid #000;
  border-radius: 4px;
  font-size: 11px;
  text-align: left;
  font-weight: bold;
}

.defect-img-container .defect-location table td {
  padding: 5px;
}
.defect-location-line {
  position: absolute;
  height: 5px;
  background-color: #000;
  z-index: -1;
}
.lug .defect-location-line {
  width: 360px;
  top: 65px;
  right: 210px;
}
.fof .defect-location-line {
  width: 100px;
  top: 5px;
  right: 210px;
}
.rif .defect-location-line {
  width: 100px;
  top: 60px;
  right: 210px;
}
.fif .defect-location-line {
  width: 60px;
  top: 5px;
  right: 215px;
}
.slot .defect-location-line {
  width: 50px;
  top: 5px;
  left: 215px;
}
.lea .defect-location-line {
  width: 320px;
  top: 20px;
  right: 100px;
}

.red-text {
  color: red;
}
.addqual-label {
  background-color: #0d1444;
  color: #fff;
  width: 35px;
  display: inline-block;
  text-align: center;
  border-radius: 2px;
}
.rsblocation-legend {
  text-align: right;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}
.rsblocation-legend > span {
  background-color: #4e4848;
  color: #fff;
  display: inline-block;
  padding: 0px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
}
.custom-height-sip {
  height: 37px;
}
.request-feature-div {
  position: absolute;
  bottom: 40px;
  left: 50px;
  height: 40px;
  width: 200px;
}
.request-feature-div .request-feature-btn {
  background-color: #fff;
  border: 4px solid #c6c8e8;
  color: #0d1444;
  font-size: 14px;
  font-weight: bold;
  transition: color 0.3s;
}
.request-feature-div .request-feature-btn:hover {
  background-color: #0d1444;
  color: #fff;
}

.request-feature-bar {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  z-index: 10000;
}
.request-feature-bar.right > div {
  right: 0;
  left: auto;
  height: 380px;
  text-align: left;
}
.request-feature-bar > div {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #fff;
  height: 520px;
  width: 500px;
}
.request-feature-bar > div > .head {
  background-color: #3d4589;
  color: #fff;
  padding: 10px 30px;
}
.request-feature-bar > div > .right-sidebar-inner {
  padding: 10px 30px;
}
.request-feature-bar > div > .right-sidebar-inner h4 {
  margin: 10px 0;
  font-weight: bold;
}
.request-feature-bar .center {
  text-align: center;
}
.request-feature-bar .center .request-feature-btn {
  width: 200px;
  background-color: #3d4589;
  font-size: 14px;
  padding: 15px;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  margin: 10px;
  cursor: pointer;
}
.request-feature-bar .center .request-feature-btn:hover {
  background-color: #2a3280;
}
.request-feature-bar .center .request-feature-btn:active {
  background-color: #1d2255;
}
.rct-tt {
  position: absolute;
  right: 10px;
}
.relative {
  position: relative;
}
.password-help {
  color: #fff;
  position: absolute;
  right: -305px;
  top: 10px;
  font-size: 11px !important;
}
.beta-tag {
  background-color: #d82c2c;
  font-weight: bold;
  padding: 5px 10px;
  font-size: 15px !important;
  margin-top: -5px;
  margin-right: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: 3px;
}
.pointer {
  cursor: pointer;
}
.no-formatting {
  color: #000;
}
.hide-pre-content {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: #333d94;
}
